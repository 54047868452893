/* ###### Accordion ###### */

.accordion-color .card-header a {
  &.collapsed {
    color: $white-8 !important;
    font-weight: 500;
    letter-spacing: -.1px;

    &:hover, &:focus {
      color: $white;
    }
  }
}


.accordion-faq .accordion .card-header a {
  background-color: #f5f5fd;
}

.accordion {
  .card {
    border-width: 0;

    + .card {
      margin-top: 1px;
    }
  }

  .card-header {
    padding: 0;
    background-color: transparent;
    font-weight: 500;

    a {
      display: block;
      padding: 14px 20px;
      position: relative;
      font-weight: 500;
      font-size: 14px;
      background-color: #e1e1f9;

      &.collapsed {
        color: #3c4858;
        background-color: #e1e1f9;
        border-bottom-color: transparent;

        &:hover, &:focus {
          color: #8f9cc0;
          background-color: $background;
          background-color: #f5f5fd;
        }
      }
    }
  }

  .card-body {
    padding: 15px;
    background-color: $white;
    transition: none;
    border: 1px solid $border;
    border-bottom: 0;
  }
}

.accordion > .card {
  overflow: hidden;

  &:not(:first-of-type) {
    .card-header:first-child {
      border-radius: 0;
    }

    &:not(:last-of-type) {
      border-bottom: 0;
      border-radius: 0;
    }
  }

  &:first-of-type {
    border-bottom: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }

  &:last-of-type {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
  }

  .card-header {
    margin-bottom: -1px;
  }
}
.collapse:not(.show) {
  display: none;
}

.collapsing {
  position: relative;
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}

@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.accordion .card-header a {
  background-color: #e1e1f9;
}

/* ###### Accordion ###### */