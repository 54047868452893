/* ###### Table  ###### */

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: $color;

  th,
  td {
    padding: 0.75rem;
    vertical-align: middle;
    padding: 9px 19px;
    line-height: 1.462;
    border-top: 0;
  }

  thead th {
    vertical-align: bottom;
    border-bottom: 1px solid $border !important;
  }

  tbody+tbody {
    border-top: 1px solid $border;
  }

  thead {

    th,
    td {
      color: #3c4858;
      font-weight: 500;
      font-size: 13px;
      letter-spacing: 0.5px;
      text-transform: uppercase;
      border-bottom-width: 1px;
      border-top-width: 0;
      padding: 11px 19px;
    }
  }

  tbody tr {
    background-color: $white-5;

    th {
      font-weight: 500;
    }
  }
}

.table-sm {

  th,
  td {
    padding: 0.3rem;
  }
}

.table-bordered {
  border: 1px solid $border !important;

  th,
  td {
    border: 1px solid $border;
  }

  thead {

    th,
    td {
      border-bottom-width: 2px;
      border-top-width: 1px;
      padding-top: 0.75rem;
      padding-bottom: 0.75rem;
      background-color: transparent;
    }
  }
}

.table-borderless {

  th,
  td,
  thead th,
  tbody+tbody {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(odd) {
  background-color: $black-05;
}

.table-hover tbody tr:hover {
  background-color: #f8f8ff;
}

.table-primary {
  background-color: #c6d4ff;

  > {

    th,
    td {
      background-color: #c6d4ff;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #95afff;
  }
}

.table-hover .table-primary:hover {
  background-color: #adc1ff;

  > {

    td,
    th {
      background-color: #adc1ff;
    }
  }
}

.table-secondary {
  background-color: #d9dde5;

  > {

    th,
    td {
      background-color: #d9dde5;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #b9c1ce;
  }
}

.table-hover .table-secondary:hover {
  background-color: #cacfdb;

  > {

    td,
    th {
      background-color: #cacfdb;
    }
  }
}

.table-success {
  background-color: #c8e9b8;

  > {

    th,
    td {
      background-color: #c8e9b8;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #99d67b;
  }
}

.table-hover .table-success:hover {
  background-color: #b9e3a5;

  > {

    td,
    th {
      background-color: #b9e3a5;
    }
  }
}

.table-info {
  background-color: #bee5eb;

  > {

    th,
    td {
      background-color: #bee5eb;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #86cfda;
  }
}

.table-hover .table-info:hover {
  background-color: #abdde5;

  > {

    td,
    th {
      background-color: #abdde5;
    }
  }
}

.table-warning {
  background-color: #ffeeba;

  > {

    th,
    td {
      background-color: #ffeeba;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #ffdf7e;
  }
}

.table-hover .table-warning:hover {
  background-color: #ffe8a1;

  > {

    td,
    th {
      background-color: #ffe8a1;
    }
  }
}

.table-danger {
  background-color: #f5c6cb;

  > {

    th,
    td {
      background-color: #f5c6cb;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #ed969e;
  }
}

.table-hover .table-danger:hover {
  background-color: #f1b0b7;

  > {

    td,
    th {
      background-color: #f1b0b7;
    }
  }
}

.table-light {
  background-color: #fcfcfd;

  > {

    th,
    td {
      background-color: #fcfcfd;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #f9fafb;
  }
}

.table-hover .table-light:hover {
  background-color: #ededf3;

  > {

    td,
    th {
      background-color: #ededf3;
    }
  }
}

.table-dark {
  color: $white;
  background-color: $dark;

  > {

    th,
    td {
      background-color: #c8ccd3;
    }
  }

  th,
  td,
  thead th,
  tbody+tbody {
    border-color: #49597b;
  }

  &.table-bordered {
    border: 0;
  }

  &.table-striped tbody tr:nth-of-type(odd) {
    background-color: $white-05;
  }

  &.table-hover tbody tr:hover {
    color: $white;
    background-color: $white-75;
  }
}

.table-hover .table-dark:hover {
  background-color: #babfc8;

  > {

    td,
    th {
      background-color: #babfc8;
    }
  }
}

.table-active {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    th,
    td {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table-hover .table-active:hover {
  background-color: rgba(0, 0, 0, 0.075);

  > {

    td,
    th {
      background-color: rgba(0, 0, 0, 0.075);
    }
  }
}

.table {
  .thead-dark th {
    color: $white;
    background-color: $dark;
    border-color: #49597b;
  }

  .thead-light th {
    color: #3c4858;
    background-color: $background;
    border-color: $background;
  }
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 767.98px) {
  .table-responsive-md {
    display: block;
    width: 100%;
    overflow-x: a;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 991.98px) {
  .table-responsive-lg {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

@media (max-width: 1199.98px) {
  .table-responsive-xl {
    display: block;
    width: 100%;
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;

    >.table-bordered {
      border: 0;
    }
  }
}

.table-responsive {
  display: block;
  width: 100%;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;

  >.table-bordered {
    border: 0;
  }
}

.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #efefff;
}

.table.dtr-details {
  margin-bottom: 0;
}

.table-shopping-cart .media {
  display: flex;
  align-items: flex-start;
  padding: 0.75rem;
}

table.table-bordered.dataTable thead tr:first-child th,
table.table-bordered.dataTable thead tr:first-child td {
  border-top-width: 0 !important;
}

.table-bordered thead th {
  background: none !important;
  border-bottom-width: 0px !important;
}

.table-responsive.tasks .table> :not(caption)>*>* {
  border-bottom: 0 !important;
}

.table-responsive.userlist-table .table> :not(caption)>*>* {
  border-bottom: 0 !important;
}


.table-striped tbody tr:nth-of-type(2n + 1) {
  background-color: #efefff;
}

.table-responsive .table-product {
  &.table> :not(caption)>*>* {
    border-bottom: 0px !important;
  }
}

table.dataTable {
  width: 100% !important;
}

/* ###### Table  ###### */